import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Form as FormBot } from "react-bootstrap";
import { saveExamination, updateExamination } from "./examination_operation";
import { useSelector } from "react-redux";

const ExaminationSection = (props) => {
  let isComplete = props.state.state == "completed";

  const [state, setState] = useState({});
  let ex = props.state?.examination;
  const [disable, setDisble] = useState(false);

  useEffect(() => {
    let temp = {};
    if (ex.length > 0) {
      ex?.forEach((e) => {
        temp[e.type] = e.result;
      });

      setState({ ...temp });
    } else {
      setState({
        spo2: "0",
        HR: "0",
        Temperature: "0",
        BPLsystolic: "0",
        BPLdiastolic: "0",
        BPRsystolic: "0",
        BPRdiastolic: "0",
        TSH: "0",
        SGPT: "0",
        RBS: "0",
        T3: "0",
        T4: "0",
        Hba1c: "0",
        height: "0",
        weight: "0",
        BMI: "0",
        sCreatinine: "0",
        sCholesterol: "0",
        EF: "0",
        sTG: "0",
        sLDL: "0",
        otherGynecologyObstetric: "",
      });
    }
  }, []);

  const calculateBMI = (height, wight) => {
    let heightSquared = ((height / 100) * height) / 100;
    let bmi = round(wight / heightSquared, 1);

    return bmi;
  };
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let types = [];
    let result = [];
    Object.entries(state).forEach(([key, value]) => {
      if (
        value.toString() != "" &&
        value.toString() != undefined &&
        value.toString() != null
      ) {
        types.push(key.toString());
        result.push(value.toString());
      }
    });
    setDisble(true);
    if (ex !== null && ex !== undefined && ex.length > 0) {
      let ids = [];
      ex.forEach((e) => {
        ids.push(e.id);
      });

      await updateExamination(types, result, props.state.id, ids);
      setDisble(false);
    } else {
      await saveExamination(types, result, props.state.id);
      setDisble(false);
    }
  };
  const userinfo = useSelector((state) => state.userLogin);
  const { userInfo } = userinfo;

  let isAdmni = userInfo.user_info.user_type == "admin";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div>
          <div className="card">
            <div className="card-header bg-info">
              <h4 className="text-uppercase d-inline-block">Examination </h4>
            </div>

            {/**/}

            {/**/}
            <div className="card-body m-auto">
              {isAdmni == false ? null : (
                <div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <div className="mb-2">
                        <label htmlFor="spo2">spo2</label>
                        <input
                          disabled={isComplete}
                          placeholder="spo2"
                          type="number"
                          step="any"
                          value={state.spo2}
                          onChange={(e) => {
                            setState({ ...state, spo2: e.target.value });
                          }}
                          className={`form-control shadow-none  ml-1 mr-1`}
                        />
                      </div>

                      <label htmlFor="HR">HR</label>
                      <input
                        placeholder="HR"
                        disabled={isComplete}
                        type="number"
                        step="any"
                        value={state.HR}
                        onChange={(e) => {
                          setState({ ...state, HR: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>

                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Temperature">Temperature</label>
                      <input
                        placeholder="Temperature"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.Temperature}
                        onChange={(e) => {
                          setState({ ...state, Temperature: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="BPLsystolic">BPLsystolic</label>
                      <input
                        placeholder="BPLsystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.BPLsystolic}
                        onChange={(e) => {
                          setState({ ...state, BPLsystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />

                      <label htmlFor="BPLdiastolic">BPLdiastolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="BPLdiastolic"
                        type="number"
                        step="any"
                        value={state.BPLdiastolic}
                        onChange={(e) => {
                          setState({ ...state, BPLdiastolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="BPRsystolic">BPRsystolic</label>
                      <input
                        placeholder="BPRsystolic"
                        type="number"
                        step="any"
                        disabled={isComplete}
                        value={state.BPRsystolic}
                        onChange={(e) => {
                          setState({ ...state, BPRsystolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                      <label htmlFor="BPRdiastolic">BPRdiastolic</label>
                      <input
                        disabled={isComplete}
                        placeholder="BPRdiastolic"
                        type="number"
                        step="any"
                        value={state.BPRdiastolic}
                        onChange={(e) => {
                          setState({ ...state, BPRdiastolic: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="TSH">TSH</label>
                      <input
                        disabled={isComplete}
                        placeholder="TSH"
                        type="TSH"
                        value={state.TSH}
                        onChange={(e) => {
                          setState({ ...state, TSH: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="T3">S.troponin</label>
                      <input
                        disabled={isComplete}
                        placeholder="T3"
                        type="number"
                        step="any"
                        value={state.T3}
                        onChange={(e) => {
                          setState({ ...state, T3: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Hba1c">T4</label>
                      <input
                        disabled={isComplete}
                        placeholder="T4"
                        type="number"
                        step="any"
                        value={state.T4}
                        onChange={(e) => {
                          setState({ ...state, T4: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="RBS">RBS</label>
                      <input
                        disabled={isComplete}
                        placeholder="RBS"
                        type="number"
                        step="any"
                        value={state.RBS}
                        onChange={(e) => {
                          setState({ ...state, RBS: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                    <div className="form-group mr-2 ml-2">
                      <label htmlFor="Hba1c">Hba1c</label>
                      <input
                        disabled={isComplete}
                        placeholder="Hba1c"
                        type="number"
                        step="any"
                        value={state.Hba1c}
                        onChange={(e) => {
                          setState({ ...state, Hba1c: e.target.value });
                        }}
                        className={`form-control shadow-none  ml-1 mr-1`}
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              )}
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="sCreatinine">S.Creatinine</label>
                  <input
                    disabled={isComplete}
                    placeholder="S.Creatinine"
                    type="number"
                    step="any"
                    value={state.sCreatinine}
                    onChange={(e) => {
                      setState({ ...state, sCreatinine: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="sCholesterol">S.Cholesterol</label>
                  <input
                    type="number"
                    step="any"
                    disabled={isComplete}
                    placeholder="S.Cholesterol"
                    value={state.sCholesterol}
                    onChange={(e) => {
                      setState({ ...state, sCholesterol: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>{" "}
              </div>
              {/*  */}
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="sTG">S.TG</label>
                  <input
                    disabled={isComplete}
                    placeholder="S.TG"
                    type="number"
                    step="any"
                    value={state.sTG}
                    onChange={(e) => {
                      setState({ ...state, sTG: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="sLDL">S.LDL</label>
                  <input
                    disabled={isComplete}
                    placeholder="S.LDL"
                    type="number"
                    step="any"
                    value={state.sLDL}
                    onChange={(e) => {
                      setState({ ...state, sLDL: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="EF">EF%</label>
                  <input
                    disabled={isComplete}
                    placeholder="EF%"
                    type="number"
                    step="any"
                    value={state.EF}
                    onChange={(e) => {
                      setState({ ...state, EF: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>{" "}
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="SGPT">SGPT</label>
                  <input
                    disabled={isComplete}
                    placeholder="SGPT"
                    type="number"
                    step="any"
                    value={state.SGPT}
                    onChange={(e) => {
                      setState({ ...state, SGPT: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>
              <hr />{" "}
              <div className="row">
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="height">height</label>
                  <input
                    disabled={isComplete}
                    placeholder="height"
                    type="number"
                    step="any"
                    value={state.height}
                    onChange={(e) => {
                      setState({
                        ...state,
                        height: e.target.value,
                        BMI: calculateBMI(e.target.value, state.weight).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="weight">weight</label>
                  <input
                    placeholder="weight"
                    type="number"
                    step="any"
                    disabled={isComplete}
                    value={state.weight}
                    onChange={(e) => {
                      setState({
                        ...state,
                        weight: e.target.value,
                        BMI: calculateBMI(state.height, e.target.value).toFixed(
                          1
                        ),
                      });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
                <div className="form-group mr-2 ml-2">
                  <label htmlFor="BMI">BMI </label>
                  <input
                    placeholder="BMI"
                    type="number"
                    step="any"
                    disabled
                    value={state.BMI}
                    onChange={(e) => {
                      setState({ ...state, BMI: e.target.value });
                    }}
                    className={`form-control shadow-none  ml-1 mr-1`}
                  />
                </div>
              </div>
              <hr />
              <label htmlFor="other">other</label>
              <textarea
                placeholder="other"
                disabled={isComplete}
                value={state.otherGynecologyObstetric}
                onChange={(e) => {
                  setState({
                    ...state,
                    otherGynecologyObstetric: e.target.value,
                  });
                }}
                className={`form-control shadow-none  ml-1 mr-1`}
              />
              <button
                className="btn btn-success w-100 mt-4"
                type="submit"
                disabled={isComplete || disable}
                s
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ExaminationSection;
